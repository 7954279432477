import React from 'react'
import { ReactComponent as MoMoIcon } from '../assets/new-icons/momo2.svg'
import { ReactComponent as BankIcon } from '../assets/new-icons/banking-svgrepo-com2.svg'
import ViSaIcon  from '../assets/new-icons/visa.png'
import  CashIcon  from '../assets/new-icons/cash.png'
import { ReactComponent as OtoIcon } from './../assets/new-icons/IconOto.svg'
import { ReactComponent as TruckIcon } from './../assets/new-icons/truckicon.svg'
import { ReactComponent as RomoocIcon } from './../assets/new-icons/romoocicon.svg'
import { ReactComponent as TTDKIcon } from './../assets/new-icons/TTDK.svg'
import { ReactComponent as CuuhoIcon } from './../assets/new-icons/cuuho.svg'
import { ReactComponent as GarageIcon } from './../assets/new-icons/garage.svg'
import AtmIcon from "../assets/new-icons/BookingHistory-icon/atmCard.png";
import L2PVIIcon from "../assets/new-icons/PVI.png";
import MICPartner from "../assets/partner/mic.png";
import SaladinPartner from "../assets/partner/Saladin.png";
import TascoPartner from "../assets/partner/Tasco.png";
import L2VNIIcon from "../assets/new-icons/VNI.png";
import { ReactComponent as MICIcon } from './../assets/new-icons/insurances-icons/saladin/MIC.svg'
import { ReactComponent as PVIIcon } from './../assets/new-icons/insurances-icons/saladin/PVI.svg'
import { ReactComponent as BSHIcon } from './../assets/new-icons/insurances-icons/saladin/BSH.svg'
import { ReactComponent as VNIIcon } from './../assets/new-icons/insurances-icons/saladin/VNI.svg'
import { ReactComponent as LibertyIcon } from './../assets/new-icons/insurances-icons/saladin/Liberty.svg'
import { ReactComponent as BaoVietIcon } from './../assets/new-icons/insurances-icons/saladin/BaoViet.svg'
import { ReactComponent as PJicoIcon } from './../assets/new-icons/insurances-icons/saladin/PJico.svg'
import { ReactComponent as VBIIcon } from './../assets/new-icons/insurances-icons/saladin/VBI.svg'
import { ReactComponent as BaoMinhIcon } from './../assets/new-icons/insurances-icons/saladin/BaoMinh.svg'
import { ReactComponent as ComputerIcon } from './../assets/new-icons/insurances-icons/saladin/computer.svg'
import { ReactComponent as MobileIcon } from './../assets/new-icons/insurances-icons/saladin/mobile.svg'
import { ReactComponent as NotificateIcon } from './../assets/new-icons/insurances-icons/saladin/notificate.svg'
import { ReactComponent as ContactIcon } from './../assets/new-icons/insurances-icons/saladin/contact.svg'
import { CreditCardOutlined } from '@ant-design/icons'
import { BiWallet } from 'react-icons/bi'
import { TARGET_ID } from './targetList'

export const INSTRUCTIONS = [
  {
    name: 'instruction1',
    url: 'https://www.youtube.com/watch?v=Z8tR-osQgoU',
    type: 'video',
  },
  {
    name: 'instruction2',
    url: 'https://www.youtube.com/watch?v=uYcQGZsXwaI',
    type: 'video'
  },
  {
    name: 'instruction3',
    url: 'https://www.youtube.com/watch?v=jE42nmeo4K8',
    type: 'video'
  },
  {
    name: 'instruction4',
    url: 'https://youtu.be/czu5qf2yIow?feature=shared',
    type: 'video'
  },
  {
    name: 'instruction5',
    url: 'https://youtu.be/UVP9bnaqlVg?feature=shared',
    type: 'video'
  },
  {
    name: 'instruction6',
    url: 'https://youtu.be/mpIQeRGv3Lg?feature=shared',
    type: 'video'
  }
]
export const STATION_STATUS_COLOR = {
  receiving: '#34AA44',
  stoppedReceiving: '#AA8934',
  inactive: '#FF1001',
}

export const BOOKINGTIME = [
  {
    label: '7h30-9h30',
    scheduleTime: '7h-9h'
  },
  {
    label: '9h30-11h30',
    scheduleTime: '9h30-11h30'
  },
  {
    label: '13h30-15h',
    scheduleTime: '13h30-15h'
  },
  {
    label: '15h-16h30',
    scheduleTime: '15h30-17h30'
  }
]
export const STATION_TYPE = {
  TTDK:{
    value: 'Trung tâm đăng kiểm',
    stationType:1,
    icon:<TTDKIcon/>
  },
  GARAGE:{
    value: 'Garage',
    stationType:3,
    icon:<GarageIcon/>
  },
  CUUHO:{
    value: 'Cứu hộ',
    stationType:4,
    icon:<CuuhoIcon/>
  },
}
export const APP_THEME = {
  TTDK:{
    value: "TTDK"
  },
  DKON:{
    value: "DKON"
  }
}
export const RATIO_IMG = {
  DEFAULT:{
    value: "16/9"
  },
  PROMOTIONNEW:{
    value: "16/12"
  }
}
export const MESSAGE_TYPE = {
  VRCHECK:{
    value: 'Cảnh báo phạt nguội',
    messageType:1,
  },
  REVIEW:{
    value: 'Review lịch hẹn',
    messageType:3,
  },
}

export const SERVICE_PROVIDED = [
  {
    index: 91,
    value: 'Tra cứu phạt nguội',
    serviceType:1
  },
  {
    index: 92,
    value: 'Gia hạn bảo hiểm TNDS',
    serviceType:4
  },
  {
    index: 93,
    value: 'Bảo dưỡng, sửa chữa xe cơ giới',
    serviceType:7
  },
  {
    index: 94,
    value: 'Cứu hộ xe bị hư hỏng',
    serviceType:10
  },
  {
    index: 95,
    value: 'Dán thẻ VETC',
    serviceType:2
  },
  // {
  //   index: 96,
  //   value: 'Đóng phí VETC',
  //   serviceType:5
  // },
  // {
  //   index: 97,
  //   value: 'Đăng kiểm xe cơ giới',
  //   serviceType:8
  // },
  // {
  //   index: 98,
  //   value: 'Đóng phí phạt nguội',
  //   serviceType:3
  // },
  {
    index: 99,
    value: 'Gia hạn BH thân vỏ',
    serviceType:6
  },
  // {
  //   index: 100,
  //   value: 'Nạp tiền ePass',
  //   serviceType:9
  // },
]

export const SCHEDULE_STATUS = [
  {
    label: 'all',
    color: '#C87800',
    key:'ALL'
  },
  {
    label: 'unconfimed',
    value: 0,
    color: '#C87800',
    key:'UNCONFIRMED'
  },
  {
    label: 'confirmed',
    value: 10,
    color: '#0870D9',
    key:'CONFIRMED'
  },
  {
    label: 'canceled',
    value: 20,
    color: '#adadad',
    key:'CANCELED'
  },
  {
    label: 'closed',
    value: 30,
    color: '#34AA44',
    key:'CLOSED'
  }
]

export const SCHEDULE_STATUS_3_0 = [
  {
    label: 'Chưa xác nhận',
    value: 0,
    color: '#ADADAD'
  },
  {
    label: 'Đã xác nhận',
    value: 10,
    color: '#FF7B42'
  },
  {
    label: 'Đã hủy',
    value: 20,
    color: '#424242'
  },
  {
    label: 'Thành công',
    value: 30,
    color: '#34AA44'
  }
]

export const VIHCLE_TYPES = [
  {
    label: 'Xe ô tô con < 9 chỗ',
    value: 1,
    icon:<OtoIcon/>
  },
  {
    label: 'Xe rơ mooc',
    value: 20,
    icon:<RomoocIcon/>
  },
  {
    label: 'Xe bán tải, phương tiện khác',
    value: 10,
    icon:<TruckIcon/>
  }
]

export const VEHICLE_SUB_TYPE = [
  {
    label: 'Xe ô tô con',
    value: 1
  },
  {
    label: 'Xe khách',
    value: 11
  },
  {
    label: 'Xe tải',
    value: 12
  },
  {
    label: 'Ô tô đầu kéo',
    value: 13
  },
  {
    label: 'Rơ moóc và sơ mi rơ moóc',
    value: 20,
  },
  {
    label: 'Phương tiện khác',
    value: 10
  },
  {
    label: 'Xe bán tải',
    value: 14
  }
]

export const VIHCLE_CATEGORY_OTO = [
  {
    label: 'Xe ô tô 4 chỗ',
    value: 1001,
    seat:4
  },
  {
    label: 'Xe ô tô 5 chỗ',
    value: 1002,
    seat:5
  },
  {
    label: 'Xe ô tô 6 chỗ',
    value: 1003,
    seat:6
  },
  {
    label: 'Xe ô tô 7 chỗ',
    value: 1004,
    seat:7
  },
  {
    label: 'Xe ô tô 8 chỗ',
    value: 1005,
    seat:8
  },
  {
    label: 'Xe ô tô 9 chỗ',
    value: 1006,
    seat:9
  }
]

export const VIHCLE_CATEGORY_BUS = [
  {
    label: 'Xe ô tô 10 chỗ',
    value: 1007,
    seat:10
  },
  {
    label: 'Xe ô tô 11 chỗ',
    value: 1008,
    seat:11
  },
  {
    label: 'Xe ô tô 12 chỗ',
    value: 1009,
    seat:12
  },
  {
    label: 'Xe ô tô 13 chỗ',
    value: 1010,
    seat:13
  },
  {
    label: 'Xe ô tô 14 chỗ',
    value: 1011,
    seat:14
  },
  {
    label: 'Xe ô tô 15 chỗ',
    value: 1012,
    seat:15
  },
  {
    label: 'Xe ô tô 16 chỗ',
    value: 1013,
    seat:16
  },
  {
    label: 'Xe ô tô 17 chỗ',
    value: 1014,
    seat:17
  },
  {
    label: 'Xe ô tô 18 chỗ',
    value: 1015,
    seat:18
  },
  {
    label: 'Xe ô tô 19 chỗ',
    value: 1016,
    seat:19
  },
  {
    label: 'Xe ô tô 20 chỗ',
    value: 1017,
    seat:20
  },
  {
    label: 'Xe ô tô 21 chỗ',
    value: 1018,
    seat:21
  },
  {
    label: 'Xe ô tô 22 chỗ',
    value: 1019,
    seat:22
  },
  {
    label: 'Xe ô tô 23 chỗ',
    value: 1020,
    seat:23
  },
  {
    label: 'Xe ô tô 24 chỗ',
    value: 1021,
    seat:24
  },
  {
    label: 'Xe ô tô 25 chỗ',
    value: 1022,
    seat:25
  },
  {
    label: 'Xe ô tô 29 chỗ',
    value: 1023,
    seat:29
  },
  {
    label: 'Xe ô tô 45 chỗ',
    value: 1024,
    seat:45
  },
  {
    label: 'Xe ô tô 52 chỗ',
    value: 1025,
    seat:52
  }
]

export const VIHCLE_CATEGORY_TRUCK = [
  {
    label: 'Xe tải dưới 1 tấn',
    value: 2002,
    maxWeight:999,
    minWeight:0
  },
  {
    label: 'Xe tải dưới 2 tấn',
    value: 2003,
    maxWeight:1999,
    minWeight:1000
  },
  {
    label: 'Xe tải dưới 3 tấn',
    value: 2004,
    maxWeight:2999,
    minWeight:2000
  },
  {
    label: 'Xe tải dưới 4 tấn',
    value: 2005,
    maxWeight:3999,
    minWeight:3000
  },
  {
    label: 'Xe tải dưới 5 tấn',
    value: 2006,
    maxWeight:4999,
    minWeight:4000
  },
  {
    label: 'Xe tải dưới 6 tấn',
    value: 2007,
    maxWeight:5999,
    minWeight:5000
  },
  {
    label: 'Xe tải dưới 7 tấn',
    value: 2008,
    maxWeight:6999,
    minWeight:6000
  },
  {
    label: 'Xe tải dưới 8 tấn',
    value: 2009,
    maxWeight:7999,
    minWeight:7000
  },
  {
    label: 'Xe tải dưới 9 tấn',
    value: 2010,
    maxWeight:8999,
    minWeight:8000
  },
  {
    label: 'Xe tải dưới 10 tấn',
    value: 2011,
    maxWeight:9999,
    minWeight:9000
  },
  {
    label: 'Xe tải dưới 11 tấn',
    value: 2012,
    maxWeight:10999,
    minWeight:10000
  },
  {
    label: 'Xe tải dưới 12 tấn',
    value: 2013,
    maxWeight:11999,
    minWeight:11000
  },
  {
    label: 'Xe tải dưới 13 tấn',
    value: 2014,
    maxWeight:12999,
    minWeight:12000
  },
  {
    label: 'Xe tải dưới 14 tấn',
    value: 2015,
    maxWeight:13999,
    minWeight:13000
  },
  {
    label: 'Xe tải dưới 15 tấn',
    value: 2016,
    maxWeight:14999,
    minWeight:14000
  },
  {
    label: 'Xe tải dưới 16 tấn',
    value: 2017,
    maxWeight:15999,
    minWeight:15000
  },
  {
    label: 'Xe tải dưới 17 tấn',
    value: 2018,
    maxWeight:16999,
    minWeight:16000
  },
  {
    label: 'Xe tải dưới 18 tấn',
    value: 2019,
    maxWeight:17999,
    minWeight:17000
  },
  {
    label: 'Xe tải dưới 19 tấn',
    value: 2020,
    maxWeight:18999,
    minWeight:18000
  },
  {
    label: 'Xe tải dưới 27 tấn',
    value: 2021,
    maxWeight:26999,
    minWeight:26000
  },
  {
    label: 'Xe tải dưới 40 tấn',
    value: 2022,
    maxWeight:39999,
    minWeight:27000
  },
  {
    label: 'Xe tải trên 40 tấn',
    value: 2023,
    maxWeight:99999,
    minWeight:40000
  }
]

export const VIHCLE_CATEGORY_GROUP = [
  {
    label: 'Xe đầu kéo dưới 19 tấn',
    value: 2024,
    maxWeight:18999,
    minWeight:0,
  },
  {
    label: 'Xe đầu kéo dưới 27 tấn',
    value: 2025,
    maxWeight:26999,
    minWeight:19000,
  },
  {
    label: 'Xe đầu kéo dưới 40 tấn',
    value: 2026,
    maxWeight:39999,
    minWeight:27000,
  },
  {
    label: 'Xe đầu kéo trên 40 tấn',
    value: 2027,
    maxWeight:99999,
    minWeight:40000,
  }
]

export const VIHCLE_CATEGORY_MOOC = [
  {
    label: 'Xe ro_mooc',
    value: 3000,
    maxWeight:99999,
    minWeight:0,
  }
]

export const VIHCLE_CATEGORY_PICKUP = [
  {
    label: 'Xe bán tải',
    value: 2001,
    maxWeight:3999,
    minWeight:0,
    seat:5
  }
]

export const VIHCLE_CATEGORY_SPECIALIZED = [
  {
    label: 'Xe chuyên dụng',
    value: 4000
  },
  {
    label: 'Xe 4 bánh có động cơ',
    value: 5000
  },
  {
    label: 'Xe cứu thương',
    value: 6000
  }
]

export const VIHCLE_CATEGORY_ORTHER = [
  {
    label: 'Xe ô tô 10 chỗ',
    value: 1007
  },
  {
    label: 'Xe ô tô 11 chỗ',
    value: 1008
  },
  {
    label: 'Xe ô tô 12 chỗ',
    value: 1009
  },
  {
    label: 'Xe ô tô 13 chỗ',
    value: 1010
  },
  {
    label: 'Xe ô tô 14 chỗ',
    value: 1011
  },
  {
    label: 'Xe ô tô 15 chỗ',
    value: 1012
  },
  {
    label: 'Xe ô tô 16 chỗ',
    value: 1013
  },
  {
    label: 'Xe ô tô 17 chỗ',
    value: 1014
  },
  {
    label: 'Xe ô tô 18 chỗ',
    value: 1015
  },
  {
    label: 'Xe ô tô 19 chỗ',
    value: 1016
  },
  {
    label: 'Xe ô tô 20 chỗ',
    value: 1017
  },
  {
    label: 'Xe ô tô 21 chỗ',
    value: 1018
  },
  {
    label: 'Xe ô tô 22 chỗ',
    value: 1019
  },
  {
    label: 'Xe ô tô 23 chỗ',
    value: 1020
  },
  {
    label: 'Xe ô tô 24 chỗ',
    value: 1021
  },
  {
    label: 'Xe ô tô 25 chỗ',
    value: 1022
  },
  {
    label: 'Xe ô tô 29 chỗ',
    value: 1023
  },
  {
    label: 'Xe ô tô 45 chỗ',
    value: 1024
  },
  {
    label: 'Xe ô tô 52 chỗ',
    value: 1025
  },
  {
    label: 'Xe tải dưới 1 tấn',
    value: 2002
  },
  {
    label: 'Xe tải dưới 2 tấn',
    value: 2003
  },
  {
    label: 'Xe tải dưới 3 tấn',
    value: 2004
  },
  {
    label: 'Xe tải dưới 4 tấn',
    value: 2005
  },
  {
    label: 'Xe tải dưới 5 tấn',
    value: 2006
  },
  {
    label: 'Xe tải dưới 6 tấn',
    value: 2007
  },
  {
    label: 'Xe tải dưới 7 tấn',
    value: 2008
  },
  {
    label: 'Xe tải dưới 8 tấn',
    value: 2009
  },
  {
    label: 'Xe tải dưới 9 tấn',
    value: 2010
  },
  {
    label: 'Xe tải dưới 10 tấn',
    value: 2011
  },
  {
    label: 'Xe tải dưới 11 tấn',
    value: 2012
  },
  {
    label: 'Xe tải dưới 12 tấn',
    value: 2013
  },
  {
    label: 'Xe tải dưới 13 tấn',
    value: 2014
  },
  {
    label: 'Xe tải dưới 14 tấn',
    value: 2015
  },
  {
    label: 'Xe tải dưới 15 tấn',
    value: 2016
  },
  {
    label: 'Xe tải dưới 16 tấn',
    value: 2017
  },
  {
    label: 'Xe tải dưới 17 tấn',
    value: 2018
  },
  {
    label: 'Xe tải dưới 18 tấn',
    value: 2019
  },
  {
    label: 'Xe tải dưới 19 tấn',
    value: 2020
  },
  {
    label: 'Xe tải dưới 27 tấn',
    value: 2021
  },
  {
    label: 'Xe tải dưới 40 tấn',
    value: 2022
  },
  {
    label: 'Xe tải trên 40 tấn',
    value: 2023
  },
  {
    label: 'Xe đầu kéo dưới 19 tấn',
    value: 2024
  },
  {
    label: 'Xe đầu kéo dưới 27 tấn',
    value: 2025
  },
  {
    label: 'Xe đầu kéo dưới 40 tấn',
    value: 2026
  },
  {
    label: 'Xe đầu kéo trên 40 tấn',
    value: 2027
  },
  {
    label: 'Xe bán tải',
    value: 2001
  },
  {
    label: 'Xe chuyên dụng',
    value: 4000
  },
  {
    label: 'Xe 4 bánh có động cơ',
    value: 5000
  },
  {
    label: 'Xe cứu thương',
    value: 6000
  }
]
export const NAVIGATION_TYPE= {
  DIRECT: { value: 1, label: "Trực tiếp"},
  EXTERNAL: { value: 2, label: "Ra ngoài"},
  INTERNAL: { value: 3, label: "Nội bộ"},
}
export const TTDK_INSURANCE_PARTNER = {
  TASCO:{
    id:1,
    label: 'Tasco',
    hotline:'1900 1562',
    type:'Overview',
    link:'https://ttdk.partner.baohiemtasco.vn/bao-hiem-tnds/mua-ngay',
    otherPartner:true,
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={TascoPartner} style={{width:'50px'}}/>
      </div>
    ),
    service:[
      {
        id:1,
        value:'Tasco1'
      },
      {
        id:2,
        value:'Tasco2'
      },
      {
        id:3,
        value:'Tasco3'
      },
      {
        id:4,
        value:'Tasco4'
      },
      {
        id:5,
        value:'Tasco5'
      },
      {
        id:6,
        value:'Tasco6'
      },
    ]
  },
  SALADIN:{
    id:TARGET_ID.SALADIN.id,
    label: 'saladin',
    hotline:'1900638454',
    type:'Overview',
    link:'https://ttdk.partner.saladin.vn/mua-bao-hiem-o-to/thong-tin-xe',
    ttdk:true,
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={SaladinPartner} style={{width:'50px'}}/>
      </div>
    ),
    service:[
      {
        id:1,
        value:'saladin1'
      },
      {
        id:2,
        value:'saladin2'
      },
      {
        id:3,
        value:'saladin3'
      },
      {
        id:4,
        value:'saladin4'
      },
    ]
  },
  MIC:{
    id:TARGET_ID.MIC.id,
    label: 'mic',
    hotline:'1900558891',
    partner:true,
    mic:true,
    type:'Army',
    link:'https://emic.vn/menuak.aspx?p=G,071_TTDK,XEL#bhhd_xelE',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={MICPartner} style={{width:'50px'}}/>
      </div>
    ),
    service:[
      {
        id:1,
        value:'mic1'
      },
      {
        id:2,
        value:'mic2'
      },
      {
        id:3,
        value:'mic3'
      },
      {
        id:4,
        value:'mic4'
      },
    ]
  },
  PVI:{
    id:TARGET_ID.SALADIN_PVI.id,
    label: 'pvi',
    hotline:'1900558891',
    type:'Oil-gas',
    link:'https://ttdk.partner.saladin.vn',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={L2PVIIcon} style={{width:'50px'}}/>
      </div>
    ),
    service:[
      {
        id:1,
        value:'pvi1'
      },
      {
        id:2,
        value:'pvi2'
      },
      {
        id:3,
        value:'pvi3'
      },
    ]
  },
  VNI:{
    id:TARGET_ID.SALADIN_VNI.id,
    label: 'vni',
    hotline:'1900558891',
    type:'Air',
    link:'https://ttdk.partner.saladin.vn',
    icon:(
      <div className="d-flex align-items-center justify-content-center">
        <img src={L2VNIIcon} style={{width:'50px'}}/>
      </div>
    ),
    service:[
      {
        id:1,
        value:'vni1'
      },
      {
        id:2,
        value:'vni2'
      },
    ]
  },
}

export const ALL_VIHCLE_CATEGORY = [
  {
    label: 'Xe ô tô 4 chỗ',
    value: 1001
  },
  {
    label: 'Xe ô tô 5 chỗ',
    value: 1002
  },
  {
    label: 'Xe ô tô 6 chỗ',
    value: 1003
  },
  {
    label: 'Xe ô tô 7 chỗ',
    value: 1004
  },
  {
    label: 'Xe ô tô 8 chỗ',
    value: 1005
  },
  {
    label: 'Xe ô tô 9 chỗ',
    value: 1006
  },
  {
    label: 'Xe ô tô 10 chỗ',
    value: 1007
  },
  {
    label: 'Xe ô tô 11 chỗ',
    value: 1008
  },
  {
    label: 'Xe ô tô 12 chỗ',
    value: 1009
  },
  {
    label: 'Xe ô tô 13 chỗ',
    value: 1010
  },
  {
    label: 'Xe ô tô 14 chỗ',
    value: 1011
  },
  {
    label: 'Xe ô tô 15 chỗ',
    value: 1012
  },
  {
    label: 'Xe ô tô 16 chỗ',
    value: 1013
  },
  {
    label: 'Xe ô tô 17 chỗ',
    value: 1014
  },
  {
    label: 'Xe ô tô 18 chỗ',
    value: 1015
  },
  {
    label: 'Xe ô tô 19 chỗ',
    value: 1016
  },
  {
    label: 'Xe ô tô 20 chỗ',
    value: 1017
  },
  {
    label: 'Xe ô tô 21 chỗ',
    value: 1018
  },
  {
    label: 'Xe ô tô 22 chỗ',
    value: 1019
  },
  {
    label: 'Xe ô tô 23 chỗ',
    value: 1020
  },
  {
    label: 'Xe ô tô 24 chỗ',
    value: 1021
  },
  {
    label: 'Xe ô tô 25 chỗ',
    value: 1022
  },
  {
    label: 'Xe ô tô 29 chỗ',
    value: 1023
  },
  {
    label: 'Xe ô tô 45 chỗ',
    value: 1024
  },
  {
    label: 'Xe ô tô 52 chỗ',
    value: 1025
  },
  {
    label: 'Xe tải dưới 1 tấn',
    value: 2002
  },
  {
    label: 'Xe tải dưới 2 tấn',
    value: 2003
  },
  {
    label: 'Xe tải dưới 3 tấn',
    value: 2004
  },
  {
    label: 'Xe tải dưới 4 tấn',
    value: 2005
  },
  {
    label: 'Xe tải dưới 5 tấn',
    value: 2006
  },
  {
    label: 'Xe tải dưới 6 tấn',
    value: 2007
  },
  {
    label: 'Xe tải dưới 7 tấn',
    value: 2008
  },
  {
    label: 'Xe tải dưới 8 tấn',
    value: 2009
  },
  {
    label: 'Xe tải dưới 9 tấn',
    value: 2010
  },
  {
    label: 'Xe tải dưới 10 tấn',
    value: 2011
  },
  {
    label: 'Xe tải dưới 11 tấn',
    value: 2012
  },
  {
    label: 'Xe tải dưới 12 tấn',
    value: 2013
  },
  {
    label: 'Xe tải dưới 13 tấn',
    value: 2014
  },
  {
    label: 'Xe tải dưới 14 tấn',
    value: 2015
  },
  {
    label: 'Xe tải dưới 15 tấn',
    value: 2016
  },
  {
    label: 'Xe tải dưới 16 tấn',
    value: 2017
  },
  {
    label: 'Xe tải dưới 17 tấn',
    value: 2018
  },
  {
    label: 'Xe tải dưới 18 tấn',
    value: 2019
  },
  {
    label: 'Xe tải dưới 19 tấn',
    value: 2020
  },
  {
    label: 'Xe tải dưới 27 tấn',
    value: 2021
  },
  {
    label: 'Xe tải dưới 40 tấn',
    value: 2022
  },
  {
    label: 'Xe tải trên 40 tấn',
    value: 2023
  },
  {
    label: 'Xe đầu kéo dưới 19 tấn',
    value: 2024
  },
  {
    label: 'Xe đầu kéo dưới 27 tấn',
    value: 2025
  },
  {
    label: 'Xe đầu kéo dưới 40 tấn',
    value: 2026
  },
  {
    label: 'Xe đầu kéo trên 40 tấn',
    value: 2027
  },
  {
    label: 'Xe ro_mooc',
    value: 3000
  },
  {
    label: 'Xe bán tải',
    value: 2001
  },
  {
    label: 'Xe chuyên dụng',
    value: 4000
  },
  {
    label: 'Xe 4 bánh có động cơ',
    value: 5000
  },
  {
    label: 'Xe cứu thương',
    value: 6000
  }
]
export const VEHICLE_PLATE_COLOR = [
  {
    label: 'Trắng',
    value: 'WHITE'
  },
  {
    label: 'Xanh',
    value: 'BLUE'
  },
  {
    label: 'Vàng',
    value: 'YELLOW'
  },
  {
    label: 'Đỏ',
    value: 'RED'
  }
]
export const VIHCLE_CATEGORY_NAME = [
  {
    label: 'Xe ô tô con < 9 chỗ',
    value: 10
  },
  {
    label: 'Xe ô tô khách < 16 chỗ',
    value: 11
  },
  {
    label: 'Xe ô tô khách 16 - 52 chỗ',
    value: 12
  },
  {
    label: 'Xe bán tải',
    value: 20
  },
  {
    label: 'Xe tải',
    value: 21
  },
  {
    label: 'Xe đầu kéo',
    value: 22
  },
  {
    label: 'Xe Romooc, sơmi romooc',
    value: 23
  },
  {
    label: 'Xe chuyên dụng',
    value: 30
  },
  {
    label: 'Xe bốn bánh có động cơ',
    value: 40
  }
]

export const VIHCLE_TYPES_STATE = {
  CAR: 0,
  OTHER_VEHICLES: 10,
  TRAILERS: 20
}

export const VEHICLE_STATUS = {
  NOT_VERIFIED: 0,
  VERIFIED: 1
}

export const IS_MOBILE_VERSION = window.location.href.includes("mobile") ? true : false

var result = navigator.userAgent.toLowerCase();
export const IS_ANDROID_DEVICE = result.indexOf("android") > -1 ? true : false
export const IS_IOS_DEVICE = result.indexOf("iphone") > -1 ? true : false

export const VEHICLE_COLOR = { 1: 'WHITE', 2: 'BLUE', 3: 'YELLOW', 4: 'RED' }

export const IS_ZALO_MINI_APP = window.APP_CONTEXT === 'zalo-mini-app'

export const CUSTOMER_RECEIPT_STATUS = {
  NEW: 'New',
  PROCESSING: 'Processing',
  PENDING: 'Pending',
  FAILED: 'Failed',
  SUCCESS: 'Success',
  CANCELED: 'Canceled'
}

export const CUSTOMER_RECEIPT_STATUS_TO_TEXT = {
  NEW: 'Mới',
  PROCESSING: 'Đang xử lý',
  PENDING: 'Chờ xử lý',
  FAILED: 'Thất bại',
  SUCCESS: 'Thành công',
  CANCELED: 'Đã hủy'
}

export const RECEIPT_ERROR = {
  STATION_DISABLE_PAYMENT: 'Trạm hiện tại không hỗ trợ thanh toán'
}

export const VIHCLE_CATEGORY = [
  {
    label: 'Xe chở người(ô tô và ô tô khách)',
    value: 1
  },
  {
    label: 'Xe chở hàng(ô tô tải)',
    value: 2
  },
  {
    label: 'Xe đầu kéo',
    value: 3
  },
  {
    label: 'Xe chuyên dùng',
    value: 4
  },
  {
    label: 'Rơ-moóc, Sơ-mi Rơ-moóc',
    value: 5
  },
  {
    label: 'Xe bốn bánh có động cơ',
    value: 6
  }
]

export const MODAL_FEE_VALUE = {
  CATEGORY: {
    title: 'Loại phương tiện',
    content: 'Phương tiện giao thông đường bộ gồm phương tiện giao thông cơ giới đường bộ, phương tiện giao thông thô sơ đường bộ.'
  },
  TOTAL_SEAT: {
    title: 'Số chỗ ngồi',
    content: 'Số lượng chỗ ngồi trên xe được lấy theo số chỗ ngồi được thể hiện trên giấy tờ xe tại mục “Số lượng người cho phép chở'
  },
  TOTAL_BERTH: {
    title: 'Số chỗ nằm',
    content: 'Số lượng chỗ nằm trên xe được lấy theo số chỗ nằm được thể hiện trên giấy tờ xe tại mục “Số lượng người cho phép chở'
  },
  TOTAL_FOOTHOLD: {
    title: 'Số chỗ đứng',
    content: 'Số lượng chỗ đứng trên xe được lấy theo số chỗ đứng được thể hiện trên giấy tờ xe tại mục “Số lượng người cho phép chở'
  },
  TOTAL_WEIGHT: {
    title: 'Khối lượng toàn bộ theo TK/CP TGGT',
    content: 'Khối lượng toàn bộ trong tính phí đường bộ được tính theo khối lượng toàn bộ theo TK/CP TGGT được ghi trong giấy tờ phương tiện'
  },
  GOODS_WEIGHT: {
    title: 'Khối lượng hàng CC theo TK/CP TGGT',
    content: ''
  }
}

export const FEE_ROUTE_ACTIVE_VALUE = {
  ROAD: {
    id: 7
  },
  INSPECTION: {
    id: 8
  }
}

export const PAYMENT_OBJECT = {
  CASH: {
    id: 1,
    value: 'cash',
    label: 'Tiền mặt',
    enablePaymentOnline: 1,
    icon: (
      <div className="d-flex align-items-center justify-content-center">
        <img src={CashIcon} style={{ width: '83px', height: '100px' }} />
      </div>
    )
  },
  // VNPAY: {
  //   id: 3,
  //   value: 'vnpay',
  //   label: 'VNPay',
  //   enablePaymentOnline: 1,
  //   icon: <VNPayIcon className="w-icon " />
  // },
  ATM: {
    id: 2,
    value: 'atm',
    label: 'Chuyển khoản ngân hàng',
    enablePaymentOnline: 1,
    // icon: (
    //   <div style={{ width: '31px', height: '31px', background: '#EBEBEB' }} className="d-flex align-items-center justify-content-center">
    //     <img src={iconBank} style={{ width: '20px', height: '20px' }} />
    //   </div>
    // )
    icon: <BankIcon className="w-icon " />
  }, // atm/bank
  MOMO: {
    id: 5,
    value: 'momo',
    label: 'Ví điện tử MoMo',
    enablePaymentOnline: 1,
    // icon: (
    //   <div style={{ width: '31px', height: '31px', background: '#EBEBEB' }} className="d-flex align-items-center justify-content-center">
    //     <img src={iconMomo} style={{ width: '31px', height: '31px' }} />
    //   </div>
    // )
    icon: <MoMoIcon className="w-icon " />
  },
  CREDIT_CARD: {
    id: 4,
    value: 'creditcard', // visa
    label: 'VISA / MASTER / JCB',
    enablePaymentOnline: 1,
    icon: (
      <div  className="d-flex align-items-center justify-content-center">
        <img src={ViSaIcon} style={{ width: '83px', height: '100px' }} />
      </div>
    )
  },
  DOMESTIC_CARD: {
    id: 6,
    value: 'domesticcard',
    label: 'Thẻ thanh toán nội địa (ATM)',
    enablePaymentOnline: 1,
    icon: (
      <div className="d-flex align-items-center justify-content-center">
        <img src={AtmIcon}  style={{ width: '75px', height: '80px',marginBottom:'10px'}}/>
      </div>
    )
  },
  MOMO_BUSINESS: {
    id: 7,
    value: 'momobusiness',
    label: 'Thanh toán qua MoMo',
    enablePaymentOnline: 1,
    icon: <MoMoIcon className="w-icon " />
  }
}

export const SALADIN_INSURANCE_PARTNER = {
  Liberty:{
    id:1,
    label: 'Liberty Insurance',
    icon:<LibertyIcon/>,
  },
  PVI:{
    id:2,
    label: 'Bảo hiểm PVI',
    icon:<PVIIcon/>,
  },
  // BSH:{
  //   id:3,
  //   label: 'Bảo hiểm BSH',
  //   icon:<BSHIcon/>,
  // },
  VNI:{
    id:4,
    label: 'Bảo hiểm VNI',
    icon:<VNIIcon/>,
  },
  BaoViet:{
    id:5,
    label: 'Bảo hiểm BAOVIET',
    icon:<BaoVietIcon/>,
  },
  // MIC:{
  //   id:6,
  //   label: 'Bảo hiểm MIC',
  //   icon:<MICIcon/>,
  // },
  PJico:{
    id:7,
    label: 'Bảo hiểm PJICO',
    icon:<PJicoIcon/>,
  },
  // VBI:{
  //   id:8,
  //   label: 'Bảo hiểm VIETINBANK',
  //   icon:<VBIIcon/>,
  // },
  BaoMinh:{
    id:9,
    label: 'Bảo hiểm BaoMinh',
    icon:<BaoMinhIcon/>,
  },
}
export const SALADIN_INSURANCE_POLICY = [
  {
    label:'buy-saladin',
    icon:<ComputerIcon></ComputerIcon>,
  },
  {
    label:'buy-saladin1',
    icon:<MobileIcon></MobileIcon>,
  },
  {
    label:'buy-saladin2',
    icon:<NotificateIcon></NotificateIcon>,
  },
  {
    label:'buy-saladin3',
    icon:<ContactIcon></ContactIcon>,
  }
]
export const VEHICLE_SUB_CATEGORY = {
  CAR: 1, //- Ô tô con
  PASSENGER: 11, //- Ô tô khách
  TRUCKER: 12, //- Xe tải
  GROUP: 13, // đoàn oto
  ROMOOCL: 20, //- Romooc, sơmi romooc
  CAR_SPECIALIZED: 14, // xe bán tải
  ORTHER: 10 // phương tiện khác
}

export const ROAD_TYPE = {
  ROAD: 'road',
  INSURANCE : 'insurance'
}
