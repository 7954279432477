import React from 'react'
import { ReactComponent as LeftIcon } from './../../assets/new-icons/arrows.svg'
import { ReactComponent as QuestionIcon } from './../../assets/new-icons/hoi.svg'
import Arrow from "../../assets/new-icons/Arrow 7.png";
import './index.scss'
import { useHistory, useLocation } from 'react-router-dom'
import useWindowDimensions from 'hooks/window-dimensions'

const StickyHeader = ({
  className,
  icon = <span></span>,
  showArrowLeft = true,
  title = null,
  // backgroundColor = 'white',
  // borderColor = '#0C42BC',
  // color = '#0C42BC',
  customBackPath = null,
  onGoBack,
  askQuestionButtonVisible = false,
  askQuestionButtonActionUrl = null,
  breakpoint = 600
}) => {
  const { width } = useWindowDimensions()
  const history = useHistory()
  const headerTitle = title ? title[0].toUpperCase() + title.slice(1) : ''
  const { search } = useLocation();
  const params = new URLSearchParams(search)
  let isEmbeddedView = Boolean(params.get('isEmbeddedView') || false)
  if (width < breakpoint){
    return (
      <>
      {
        !isEmbeddedView && (
          <div
            className={`sticky-header ${className || ''}`}
          >
            <div
              style={{
                visibility: showArrowLeft ? 'visible' : 'hidden'
              }}
              onClick={() => {
                if (onGoBack) {
                  onGoBack()
                } else {
                  customBackPath && showArrowLeft ? history.push(customBackPath) : history.goBack()
                }
              }}
              className="cursor">
              <img src={Arrow} />
            </div>
            <div className="sticky-header-title">
              {icon}
              <span className="mx-2" style={{ color : 'white'}}>{headerTitle}</span>
            </div>
            <div
              style={{ visibility: askQuestionButtonVisible ? 'visible' : 'hidden' }}
              className=" cursor style-color"
              onClick={() => {
                history.push(askQuestionButtonActionUrl)
              }}>
              <QuestionIcon width="16px" height="16px" color="#0C42BC" />
            </div>
          </div>
        )
      }
      </>
    )
  }
  return (
    <>
      {!isEmbeddedView && (
        <div
          className={`desktop-header ${className || ''}`}
        >
          <div
            style={{
              visibility: showArrowLeft ? 'visible' : 'hidden'
            }}
            onClick={() => {
              if (onGoBack) {
                onGoBack()
              } else {
                customBackPath && showArrowLeft ? history.push(customBackPath) : history.goBack()
              }
            }}
            className="cursor back-btn">
            <LeftIcon />
          </div>
          <div className="desktop-header-title">
            {icon}
            <span className="mx-2">{headerTitle}</span>
          </div>
          <div
            style={{ visibility: askQuestionButtonVisible ? 'visible' : 'hidden' }}
            className="p-2 cursor style-color"
            onClick={() => {
              history.push(askQuestionButtonActionUrl)
            }}>
            <QuestionIcon width="17px" height="17px" color="#0C42BC" />
          </div>
        </div>
      )}
    </>
    )
}

export const StickyHeaderContainer = (props) => {
  return (
    <div style={{ maxWidth: 600, width: '100%' , margin: 'auto'  }}>
      <StickyHeader {...props} />
    </div>
  )
}

export default StickyHeader;