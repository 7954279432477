import { tablet } from 'constants/breakpoints'
import { smallScreenLimit } from 'constants/breakpoints'
import { grayGainsboro } from 'constants/colors'
import { blackRaisin } from 'constants/colors'
import { redLust } from 'constants/colors'
import { blueSapphire } from 'constants/colors'
import { darkLiver } from 'constants/colors'
import { ghostWhite } from 'constants/colors'
import { white } from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div`
  max-width: 652px;
  width: 652px;
  min-height: 408px;

  background: ${white};

  border: 1px solid ${grayGainsboro};

  @media (max-width: ${smallScreenLimit}) {
    flex: 1;
    width: 100%;
  }
`

export const Form = styled.form``

export const FormTitle = styled.div`
  width: 100%;
  height: 58px;

  background: ${ghostWhite};
  border-bottom: 1px solid ${grayGainsboro};
  text-align: center;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;

  text-transform: uppercase;
  color: ${blackRaisin};
`

export const FormContent = styled.div`
  padding: 40px 28px 24px 28px;
`

export const Label = styled.label`
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${blackRaisin};

  margin-bottom: 8px;

  &::before {
    content: '*';
    color: #f65050;
    margin-right: 4px;

    display: inline-block;
  }
`

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 28px 12px;
  margin-bottom: 40px;

  width: 100%;
  height: 60px;

  background: rgba(221, 221, 221, 0.15);
  border: 1px solid ${grayGainsboro};
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${darkLiver};
  opacity: 0.7;
`

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin-top: 40px;

  width: 100%;
  height: 46px;

  background: ${blueSapphire};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 6px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  text-align: center;

  color: ${white};

  border: none;
  outline: none;

  &:disabled {
    opacity: 0.6;
  }
`

export const Loading = styled.div`
  margin-top: 24px;
`
export const Row = styled.div`
  margin-top: 24px;
  overflow: hidden;
`

export const Error = styled.div`
  color: ${redLust};
`
